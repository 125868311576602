/* .text input, */
.text,
.text:active,
.text:focus,
.text:hover {
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  height: 38px;
  width: 100%;
  font-family: Roboto-Regular !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
}

.text input {
  font-family: Roboto-Regular !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
}
