body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Muli-Bold';
  src: local('Muli-Bold'), url(./assets/fonts/Muli-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli-ExtraBold';
  src: local('Muli-ExtraBold'),
    url(./assets/fonts/Muli-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli-SemiBold';
  src: local('Muli-SemiBold'),
    url(./assets/fonts/Muli-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli';
  src: local('Muli'), url(./assets/fonts/Muli.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli-Light';
  src: local('Muli-Light'),
    url(./assets/fonts/Muli-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: local('HelveticaNeue-Bold'),
    url(./assets/fonts/HelveticaNeue-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}
@import url('https://fonts.googleapis.com/earlyaccess/notokufiarabic.css');
