@import url(https://fonts.googleapis.com/earlyaccess/notokufiarabic.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Muli-Bold';
  src: local('Muli-Bold'), url(../../static/media/Muli-Bold.1e36ee6d.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli-ExtraBold';
  src: local('Muli-ExtraBold'),
    url(../../static/media/Muli-ExtraBold.019351fc.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli-SemiBold';
  src: local('Muli-SemiBold'),
    url(../../static/media/Muli-SemiBold.e74e966f.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli';
  src: local('Muli'), url(../../static/media/Muli.df733025.ttf) format('truetype');
}

@font-face {
  font-family: 'Muli-Light';
  src: local('Muli-Light'),
    url(../../static/media/Muli-Light.6b667c3d.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: local('HelveticaNeue-Bold'),
    url(../../static/media/HelveticaNeue-Bold.7f281199.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url(../../static/media/Poppins-Medium.a4e11dda.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url(../../static/media/Poppins-SemiBold.e63b93df.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url(../../static/media/Poppins-Regular.731a28a4.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url(../../static/media/Roboto-Regular.3e1af3ef.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url(../../static/media/Roboto-Medium.d0884059.ttf) format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.login_layout__28otZ {
  min-height: 100vh;
  flex: 1 1;
  justify-content: center;
  background-color: #fafafa;
}

.login_container__3ulnk {
  box-shadow: 3px -5px 40px 0 rgba(205, 205, 212, 0.1);
  border-radius: 50px;
  background-color: #ffffff;
}

.login_branding__1iYx1 {
  padding: 48px;
  border: solid 1px #f1f1f5;
  background-color: #fafafb;
  display: flex;
  align-self: stretch;
  border-start-start-radius: 50px;
  border-end-start-radius: 50px;
}

.login_form__2HkxO {
  padding: 60px;
  align-self: stretch;
  border-start-start-radius: 50px;
  border-start-end-radius: 50px;
}

@media screen and (max-width: 768px) {
  .login_container__3ulnk {
    border-radius: 0;
  }

  .login_branding__1iYx1 {
    border-radius: 0;
  }

  .login_form__2HkxO {
    border-radius: 0;
  }
}

.login_art__2pCu6 {
  width: 80.3%;
}

.login_space__3Z_O1 {
  width: 100%;
}

.login_errorTextStyle__2f_jd {
  padding-left: 22px;
  color: red;
  font-family: ProximaNova-Regular;
  font-size: 12px;
}

/* .text input, */
.input_text__1hGnC,
.input_text__1hGnC:active,
.input_text__1hGnC:focus,
.input_text__1hGnC:hover {
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  height: 38px;
  width: 100%;
  font-family: Roboto-Regular !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
}

.input_text__1hGnC input {
  font-family: Roboto-Regular !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
}

.typography_h1__2fzoO {
  font-family: Poppins-SemiBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  color: #171725;
  margin-bottom: 30px;
}

.typography_h2__1wmLQ {
  font-family: Poppins-SemiBold;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: #171725;
  margin-bottom: 30px;
}

.typography_subtitle___z1kV {
  font-family: Poppins-Medium;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  color: #696974;
  margin-bottom: 0;
}

.button_primary__1t7EX {
  border-radius: 10px;
  background-color: #9e1c96;
  border-color: #9e1c96;

  /* span */
  font-family: Poppins-SemiBold;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.button_secondary__3uc2b {
  border-radius: 10px;
  background-color: #f1f1f5;
  border-color: #f1f1f5;

  /* span */
  font-family: Poppins-SemiBold;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #696974;
}

.button_primary__1t7EX svg,
.button_secondary__3uc2b svg {
  vertical-align: middle;
}

.button_primary__1t7EX:hover,
.button_primary__1t7EX:active,
.button_primary__1t7EX:focus {
  background-color: #9e1c96;
  border-color: #9e1c96;
  opacity: 0.8;
}

.button_secondary__3uc2b:hover,
.button_secondary__3uc2b:active,
.button_secondary__3uc2b:focus {
  background-color: #f1f1f5;
  border-color: #f1f1f5;
  color: #696974;
  opacity: 0.8;
}

.button_link__3Wqh4 {
  padding: 0;
}

.button_link__3Wqh4 > span {
  color: #9e1c96;
  font-family: Roboto-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
}

.button_link__3Wqh4:hover > span {
  text-decoration: underline !important;
}

